
.details-page-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding: 0 10%; */
    height: fit-content;
    min-height: 100vh;
}

.details-nav {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.details-nav-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.details-top {
    width: 100%;
    background-image: url('./adien.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 110px;
}

.details-top-inner {
    padding: 0 10%;
}

.markets-back {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
}

.markets-text {
    color: white;
}

.fa-caret-right {
    color: white;
}

.markets-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.details-searchbar-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 35%;
}

.details-searchbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 3px 8px;
    background-color: white;
    border-radius: 10px;
    font-size: 10pt;
    border: 1px solid lightgray;
    width: 100%;
}

.details-searchbar-container input {
    padding: 5px;
    height: 25px;
    font-size: 10pt;
    border: 0;
    width: 100%;
}

.details-searchbar-container input:focus {
    border: 0 !important;
    outline: none;
}

.details-search-results {
    position: absolute;
    margin-top: 42.5px;
    z-index: 1000;
    background-color: white;
    border: 1px solid lightgray;


    list-style: none;
    padding: 0;
    font-size: 10pt;
    font-weight: 300;
    width: 28%;
}

.details-search-results li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid lightgray;
    gap: 20px;
    /* width: 100%; */
}

.details-search-results li:hover {
    cursor: pointer;
    background-color: #91d7ff;
    font-weight: 500;
    /* text-decoration: underline; */
}

.hidden-search {
    display: none !important;
}

.details-top-content {
    display: flex;
    /* justify-content: space-between; */
}

.info-top {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.info-top h4 {
    margin: 0 !important;
    padding-bottom: 0 !important;
    font-size: 28pt;
    font-weight: 300;
    color: white;
}

.info-top h5 {
    margin: 0 !important;
    font-size: 14pt;
    font-weight: 300;
    padding-top: 5px;
    color: white;
}

.stock-title {
    display: flex;
    gap: 25px;
    align-items: center;
}

.stock-title-right {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.stock-title h1 {
    margin: 0 !important;
    font-size: 48pt;
    font-weight: 300;
    color: white;
}

.return-warning {
    color: white;
    font-size: 8pt;
    display: flex;
    align-items: center;
    gap: 5px;
}

.fa-circle-info {
    color: white;
}

.return-warning span {
    color: white;
}

.details-buttons {
    padding-top: 25px;
    margin-bottom: 50px;
    display: flex;
    gap: 20px;
    font-size: 20pt !important;
}

.details-buttons button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 18px;
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
}

.details-buttons button:hover {
    background-color: #9edbff;
    border: 1px solid #9edbff;
    color: #9edbff !important;
}

.details-buttons button i {
    color: #2081ff !important;
    font-size: 14pt;
}

.details-buttons button span {
    color: #2081ff !important;
    font-size: 14pt;
}

#trade-button {
    background-color: transparent;
    border: 1px solid white;
}

#trade-button:hover {
    background-color: #9edbff;
    border: 1px solid #9edbff;
}

#trade-button i {
    color: white !important;
}

#trade-button span {
    color: white !important;
}

.stock-sector {
    font-size: 10pt;
    font-weight: 300;
    color: white;
}

.details-price-return {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.details-return {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    font-size: 14pt;
}

#details-price {
    font-size: 28pt;
    font-weight: 300;
    color: white;
}

#detail-positive {
    color: rgb(0, 255, 0);
}

#detail-negative {
    color: rgb(255, 0, 0)
}

.details-return-percent {
    font-weight: 300;
    padding-bottom: 4px;
}

.info-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 5%;
    width: 100%;
    margin-left: 1%;
}

.info-stats-col {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 15px;
}

.info-stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.info-stat-item h4 {
    margin: 0 !important;
    font-size: 11pt;
    font-weight: 300;
    color: white;
}

.info-stat-item h5 {
    margin: 0 !important;
    font-size: 12pt;
    color: white;
}

.info-graph-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    /* padding: 20px; */
    gap: 30px;
    width: 80%;
    margin: 25px 0;
    margin-top: 40px;
    margin-bottom: 80px;
}

.graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    /* align-items: center; */
    /* border: 1px solid lightgray; */
    height: 100%;
    width: 100%;
    /* border-radius: 5px; */
    /* padding: 30px; */
}

.graph-buttons {
    display: flex;
    gap: 7px;
}

.graph-buttons button {
    cursor: pointer;
    font-size: 12pt;
    /* font-weight: 700; */
    padding: 6px 12px;
    background-color: rgb(241, 241, 241);
    border: none;
    border-radius: 5px;
}

.graph-buttons button:hover {
    color: #2081ff;
}

.graph-buttons button:disabled {
    cursor: not-allowed;
    background-color: #aaa;
    color: #555;
}

.active-timeframe {
    background-color: #2081ff !important;
    color: white !important;
}

.active-timeframe:hover {
    color: white;
}

.about-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-bottom: 40px; */
}

.about-section h3 {
    margin: 0;
}
