
.trading-splash-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 4fr 6fr;
    justify-content: center;
    align-items: center;
    /* padding: 100px 5%; */
}

.trading-splash-left {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.trading-splash-header {
    padding-left: 15%;
    padding-right: 10%
}

.trading-splash-header h2 {
    font-size: 40pt;
    color: #00357a;
    margin: 0 !important;
}

.trading-splash-header h3 {
    font-size: 20pt;
    font-weight: 300;
    color: #00357a;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

#trading-video-background {
    width: 100%;
}

.video-container {
    position: relative;
}

.video-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    z-index: 1;
}

.searchbar-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    padding-left: 15%;
    z-index: 5000;
}

.searchbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 7px 10px;
    background-color: white;
    border-radius: 10px;
    font-size: 14pt;
    border: 1px solid lightgray;
    width: 500px;
}

.searchbar-container:focus {
    border: 2px solid #2081ff;
}

.searchbar-container input {
    padding: 5px;
    height: 25px;
    font-size: 14pt;
    border: 0;
    width: 100%;
}

.searchbar-container input:focus {
    border: 0 !important;
    outline: none;
}


.search-results {
    position: absolute;
    margin-top: 50px;
    z-index: 1000;
    background-color: white;
    border: 1px solid lightgray;


    list-style: none;
    padding: 0;
    font-size: 10pt;
    font-weight: 300;
    width: 500px;
}

.search-results li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid lightgray;
    gap: 20px;
}

.search-results li:hover {
    cursor: pointer;
    background-color: #91d7ff;
    font-weight: 500;
    text-decoration: underline;
}

.hidden-search {
    display: none !important;
}
