.markets-container {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    padding: 20px 10%;
    padding-bottom: 40px;
    min-height: 100vh;
    padding-top: 120px;
}

.markets-container h2 {
    margin: 0 !important;
    margin-top: 20px !important;
    padding-left: 10px;
}

.markets-container h4 {
    margin: 5px 0 !important;
}

.markets-container h5 {
    margin: 0 !important;
    font-weight: 300;
    font-size: 8pt;
}

.markets-top-div {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    /* margin-bottom: 20px; */
}

.three-col-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
    margin-bottom: 20px;
}

.index-box {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0px 0px 20px rgba(107, 107, 107, 0.1);
}

.index-box-top {
    display: flex;
    justify-content: space-between;
    padding: 7px;
    padding-left: 7px;
}

.index-box-top-right {
    padding-right: 5px;
    text-align: right;
}

.list-container {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(107, 107, 107, 0.1);
}

.list-container h4 {
    padding-left: 5px;
    padding-bottom: 5px;
}

.list-header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    font-size: 10pt;
    font-weight: 300;
    padding: 5px 7px;
    border-radius: 3px;
    background-color: rgb(222, 242, 254);
    margin-bottom: 5px;
}

.list-right {
    display: flex;
    justify-content: flex-end;
}

.list-content {
    gap: 5px;
}

.list-item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    font-size: 10pt;
    padding: 5px 7px;
}

.two-col-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2%;
    padding-bottom: 20px;
}

.indicator-box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(107, 107, 107, 0.1);
}

.indicator-box-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.indicator-timeframe {
    display: flex;
    gap: 5px;
}

.indicator-timeframe button {

}

.indicator-timeframe button {
    cursor: pointer;
    font-size: 10pt;
    /* font-weight: 700; */
    padding: 6px 12px;
    background-color: rgb(241, 241, 241);
    border: none;
    border-radius: 5px;
}

.indicator-timeframe button:hover {
    color: #2081ff;
}

/* .indicator-timeframe button:disabled {
    cursor: not-allowed;
    background-color: #aaa;
    color: #555;
} */

/* .active-timeframe {
    background-color: #2081ff !important;
    color: white !important;
} */

/* .active-timeframe:hover {
    color: white;
} */

.indicator-graph {
    max-height: 350px;
    padding-left: 2%;
}
