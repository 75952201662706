.watchlist-modal {
    position: fixed;
    top: -16px;
    right: 0;
    height: 100%;
    width: 20%;
    padding: 40px 30px;
    background-color: #FFFFFF;
    box-shadow: -10px 0px 50px #e5e5e5;
    transition: right 10s ease-in-out;
    z-index: 5000;
}

.watchlist-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

#exit-watchlist {
    cursor: pointer;
    font-size: 16pt;
    padding: 5px;
    padding-left: 0;
    width: 30px;
    background-color: transparent;
    border: none;
}

.fa-x:hover {
    color: red !important;
}

.watchlist-header {
    font-size: 14pt;
    border-bottom: 2px solid lightgray;
    padding-bottom: 5px;
}

.lists-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.single-list-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 3px;
}

.single-list-header-left {
    display: flex;
    gap: 3px;
}

.single-list-header button {
    cursor: pointer;
    background-color: transparent;
    border: 0 !important;
}

.fa-caret-up {
    color: #2081ff !important;
}

.fa-caret-down {
    color: #2081ff !important;
}

.fa-trash {
    color: #2081ff !important;
}

.fa-trash:hover {
    color: red !important;
}

.list-elements {
    margin-left: 10px;
    border-left: 1px solid #2081ff;
    gap: 10px;
}

.list-item-line {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
}

.list-item-line button {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #2081ff;
    color: #2081ff !important;
    border-radius: 3px;
}
