
.three-container {
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
  width: 100%;
  padding: 30px 5%;
  box-shadow: 0 2px 50px rgba(107, 107, 107, 0.1);
}

.two-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 5%;

  box-shadow: 0 2px 50px rgba(107, 107, 107, 0.1);

  position: fixed;
  top: 0;
  width: 90%;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
}

.nav-left {
  display: flex;
  gap: 20px;
  width: fit-content;
}

.nav-mid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* width: 100%; */
  gap: 30px;
  font-size: 13pt;
  font-weight: bold;
}

.nav-mid div {
  cursor: pointer;
  color: rgb(60, 60, 60);
}

.nav-mid div:hover {
  color: black;
}

.nav-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  width: fit-content;
  z-index: 5000;
}

.nav-button {
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: bold;
}

#nav-fill {
  color: white;
  background-color: #2081ff;
  border: 1px solid #2081ff;
  width: auto;
}

#nav-fill:hover {
  background-color: #38b6ff;
  border: 1px solid #38b6ff;
}

#nav-no-fill {
  color: #2081ff;
  background-color: transparent;
  border: 1px solid #2081ff;
}

#nav-no-fill:hover {
  color: #38b6ff;
  border: 1px solid #38b6ff;
}

.home-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 24pt;
  font-weight: 700;
}

.logo-image {
    height: 40px;
    width: auto;
}


/* .profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
} */

.active-page {
  color: #2081ff !important;
}
