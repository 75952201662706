.news-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 10%;
    min-height: 100vh;
    padding-top: 120px;
}

.topic-buttons {
    display: flex;
    gap: 10px;
}

.topic-buttons button {
    cursor: pointer;
    font-size: 12pt;
    margin: none;
    padding: 8px 12px;
    border: 2px solid rgb(240, 240, 240);
    background-color: rgb(240, 240, 240);
    border-radius: 25px;
    display: flex;
}

.topic-buttons button:hover {
    border: 2px solid rgb(215, 215, 215);
    background-color: rgb(215, 215, 215);
}

.topic-buttons button:active {
    border: 2px solid white;
}

.active-topic {
    color: white;
    border: 2px solid #2081ff !important;
    background-color: #2081ff !important;
}

.active-topic:hover {
    border: 2px solid #38b6ff !important;
    background-color: #38b6ff !important;
}

.article-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* gap: 15px; */
    border: 1px solid rgb(215, 215, 215);
    margin-bottom: 20px;
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
}

.article-left {
    height: 100%;
    overflow: hidden;
}

.article-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.article-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.article-right-top {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.article-title {
    font-weight: 700;
}

.article-subtitle {
    color: gray;
    font-weight: 300;
    font-size: 10pt;
}

.article-right a {
    color: #2081ff !important;
}
.article-right a:hover {
    color: #38b6ff !important;
}

/* .article-right a i {
    color: #2081ff !important;
}
.article-right a i:hover {
    color: #38b6ff !important;
} */
