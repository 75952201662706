/* primary color: #004aad */
/* secondary color: #2081ff */
/* tertiary color: #38b6ff */

/* secondary text color: rgb(60, 60, 60) */


* {
    color: #000000;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
}

#return-positive {
    color: green;
}

#return-negative {
    color: red;
}

#symbol-link:hover {
    cursor: pointer;
    color: #2081ff;
    text-decoration: underline;
}
