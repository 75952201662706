.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    min-height: 100vh;
}

#loading-vid {
    position: relative;
    top: 0;
    left: 15em;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
