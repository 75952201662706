

.trading-page-container {
    display: flex;
    padding: 3% 15%;
    height: fit-content;
    min-height: 100vh;
    padding-top: 160px;
}

.trading-page-left {
    /* Add styles for the left section as needed */
    flex: 1;
    width: 50%;
    padding-right: 50px;
}

.trading-page-right {
    width: 40%;
    height: auto;
}

.trading-transaction-container {
    position: sticky;
    top: 160px;
    border: 2px solid rgb(235, 235, 235);
    background-color: rgb(235, 235, 235);
    border-radius: 25px;
    /* height: 80vh; */
    height: fit-content;
    margin-bottom: 40px;
}

.trading-transaction-container h4 {
    margin: 15px 0 !important;
}

.trading-searchbar-div {
    width: 97.2%;
}

.trading-search-results {
    position: absolute;
    z-index: 1;
    background-color: white;
    border: 1px solid lightgray;


    list-style: none;
    padding: 0;
    font-size: 10pt;
    font-weight: 300;
    width: 38.5%;
}

.trading-search-results li {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid lightgray;
    gap: 25px;
}

.trading-search-results li:hover {
    cursor: pointer;
    background-color: #91d7ff;
    font-weight: 500;
    text-decoration: underline;
}

.hidden-search {
    display: none !important;
}

.trading-info-top {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
}

.trading-info-top h4 {
    margin: 0 !important;
    padding-bottom: 0 !important;
    font-size: 18pt;
}

.trading-info-top h5 {
    margin: 0 !important;
    font-size: 8pt;
    font-weight: 300;
    padding-top: 5px;
}

.trading-stock-title {
    display: flex;
    gap: 10px;
}

.trading-stock-title h1 {
    margin: 0 !important;
    font-size: 20pt;
}

.trading-stock-title button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 4px 10px;
    background-color: #2081ff;
    border: 1px solid #2081ff;
    border-radius: 50px;
    font-size: 9pt;
}

.trading-stock-title button:hover {
    background-color: #004aad;
    border: 1px solid #004aad;
}

.trading-stock-title button i {
    color: white !important;
}

.trading-stock-title button span {
    color: white !important;
}

.stock-return {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;
    font-size: 11pt;
}


.trading-info-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 5%;
    width: 90%;
    margin: 20px 10px;
    margin-left: 20px;
}

.trading-info-stats-col {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 10px;
}

.trading-info-stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 80%;
}

.trading-info-stat-item h4 {
    margin: 0 !important;
    font-size: 10pt;
    font-weight: 300;
}

.trading-info-stat-item h5 {
    margin: 0 !important;
    font-size: 11pt;
}

.transaction-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 50px;
    /* border-bottom: 2px solid #2081ff; */
}

.transaction-top button {
    cursor: pointer;
    background-color: transparent;
    color:  #2081ff;
    border: 0;
    font-size: 14pt;
    font-weight: 700;
}

.active-transaction {
    background-color:  #2081ff !important;
    color: white !important;
}

#buy-button {
    border-top-left-radius: 25px;
}

#sell-button {
    border-top-right-radius: 25px;
}


.transaction-content {
    padding: 15px 40px;
    border-bottom: 1px solid lightgray;
}

.transaction-content div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.transaction-content input {
    text-align: right;
}

.quantity-input {
    border: 0;
    background-color: white;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
}

.transaction-content select {
    height: 25px;
    padding: 5px;
    border: 0;
    border-radius: 5px;
}

.tranche-info {
    display: flex !important;
    align-items: center !important;
    flex-direction: column;
    width: 100%;
    padding-bottom: 12px;
}

.tranche-info div {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
    /* gap: 10%; */
    font-size: 10pt;
    margin: 0 !important;
}

.quantity-select {
    display: flex !important;
    flex-direction: column;
}

.quantity-select p {
    margin: 0 !important;
    margin-top: 2px;
    font-size: 8pt;
    color: red;
}

#tranche-info-right {
    display: flex;
    justify-content: flex-end !important;
}

.transaction-totals {
    padding: 15px 40px;
    border-bottom: 1px solid lightgray;
}

.transaction-totals div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.transaction-bottom {
    padding: 40px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    /* height: fit-content; */
}

.transaction-bottom button {
    cursor: pointer;
    width: 100%;
    height: 60px;
    border-radius: 11px !important;
    border: 2px solid #2081ff !important;
    background-color: #2081ff;
    color: white;
}

.transaction-bottom button:hover {
    background-color: #38b6ff;
    border: 2px solid #38b6ff !important;
}

.transaction-bottom button:active {
    border: none !important;
}

.transaction-bottom button[disabled] {
    background-color: #ccc;
    border: 2px solid #ccc !important;
    color: #666;
    cursor: not-allowed;
}

.transaction-bottom p {
    color: red;
}
