.portfolio-container {
    padding: 3% 10%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 50px;
    /* min-width: 100vh; */
    padding-top: 140px;
}

.portfolio-left {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.left-box {
    padding: 15px;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 15px;
}

.left-box h4 {
    margin: 0 !important;
    margin-bottom: 10px !important;
}

.left-box li {
    list-style: none;
    display: flex;
    justify-content: space-between;
}

#transaction-header {
    display: grid;
    grid-template-columns: 2fr 1fr 5fr 1fr 2fr;
    gap: 2%;
    font-size: 12pt;
    font-weight: 700;
    margin-bottom: 5px;
    background-color: rgb(149, 215, 255);
    border-radius: 3px;
    padding: 2px 5px;
}

.transaction-item {
    padding: 2px 5px;
    display: grid;
    grid-template-columns: 2fr 1fr 5fr 1fr 2fr;
    gap: 2%;
    font-size: 11pt;
}

.portfolio-right {
    padding: 15px;
    border: 1px solid rgb(210, 210, 210);
    border-radius: 20px;
}

.portfolio-right h4 {
    margin: 0 !important;
    margin-bottom: 10px !important;
}


.holding-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 10px 0;
}

.holding-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5%;
    font-size: 12pt;
    font-weight: 700;
    margin-bottom: 5px;
    background-color: rgb(149, 215, 255);
    border-radius: 3px;
    padding: 2px 5px;
    /* text-align: center; */
}

.holding-item {
    padding: 2px 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 12pt;
}

/* #total-value {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 80%;
} */

#total-holding {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    /* margin-right: 50px; */
}

.rightmost-column {
    display: flex;
    justify-content: flex-end;
    text-align: right !important;
    margin-right: 5px;
}

.middle-column {
    text-align: right;
}

.middle-align {
    text-align: center;
}

.leftmost-column {
    margin-left: 5px;
}

.cash-balance {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
}
